import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { MerchantModel } from '../../apis';
import * as api from '../../apis';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

type Props = {
    showModal: boolean;
    toggle: () => void;
    onSubmit: (merchant: MerchantModel) => void;
    merchants: MerchantModel[];
    selectedMerchant: MerchantModel;
};

type State = {
    selectedMerchant: MerchantModel;
    searchQuery: string;
    isLoading: boolean;
};

export default class SelectMerchantModal extends React.Component<Props, State> {
    state: State = {
        selectedMerchant: this.props.selectedMerchant,
        searchQuery: '',
        isLoading: false,
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.selectedMerchant !== prevProps.selectedMerchant) {
            this.setState({ selectedMerchant: this.props.selectedMerchant });
        }
    }

    onSelectMerchant = async (merchant: MerchantModel) => {
        this.setState({ isLoading: true });
        const selectedMerchant = await api.getMerchant(merchant.id);
        this.setState({ selectedMerchant: selectedMerchant });
        this.setState({ isLoading: false });
    };

    onInputChange = (value: string) => {
        this.setState({ searchQuery: value });
    };

    filteredMerchants = (): MerchantModel[] => {
        const { searchQuery } = this.state;
        const { merchants } = this.props;

        return merchants.filter((merchant) => merchant.display_name.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    render() {
        const { showModal } = this.props;
        const { selectedMerchant, isLoading, searchQuery } = this.state;

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalHeader className="text-capitalize card-header" toggle={this.props.toggle}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="w-50">Select Merchant</div>
                        <div className="w-50 d-flex align-items-center">
                            <FontAwesomeIcon icon={faSearch} size="xs" />
                            <Input
                                bsSize={'sm'}
                                value={searchQuery}
                                onChange={(e) => this.onInputChange(e.target.value)}
                                placeholder={'Search by Name'}
                            />
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="overflow-auto" style={{ minHeight: '30vh', maxHeight: '80vh' }}>
                    <div className="list-group">
                        {this.filteredMerchants().map((el) => {
                            const style = ['my-1 btn', selectedMerchant?.id === el.id && 'active'].join(' ');
                            return (
                                <li
                                    className={`${style} list-group-item list-group-item-action`}
                                    key={el.id}
                                    onClick={() => this.onSelectMerchant(el)}
                                >
                                    {el.display_name}
                                </li>
                            );
                        })}
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button
                        block={true}
                        color="primary"
                        disabled={isLoading}
                        onClick={() => this.props.onSubmit(selectedMerchant)}
                    >
                        {isLoading ? <Loader color={'blue'} size="sm" /> : `Switch Merchant`}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
